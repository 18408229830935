import React, { useState, useEffect } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const SliderComponent = ({ label, label_to_show, min, max, step, value, onChange, info, scale, slider_scale_info }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showScaleInfo, setScaleShowInfo] = useState(false);

  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll('.info-popup');
    let isOutside = true;

    popups.forEach((popup) => {
      if (popup.contains(event.target)) {
        isOutside = false;
      }
    });

    if (isOutside) {
      setShowInfo(false);
      setScaleShowInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleCloseClick = () => {
    setShowInfo(false);
  };

  const handleScaleInfoClick = () => {
    setScaleShowInfo(true);
  };

  const handleScaleCloseClick = () => {
    setScaleShowInfo(false);
  };

  return (
    <div className="slider-container">
      <div className="slider-label">
        <label onClick={handleInfoClick}>{label}</label>
        <FaInfoCircle className="info-icon" onClick={handleInfoClick} />
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
      {scale.length > 0 && (
        <div className="slider-scale" onClick={handleScaleInfoClick}>
          {scale.map((scaleValue, index) => (
            <span key={index} className="slider-scale-value">
              {scaleValue}
            </span>
          ))}
        </div>
      )}
      {showInfo && (
        <div className="info-popup">
          <div className="info-content">
            <p>{info}</p>
            <button onClick={handleCloseClick}>Close</button>
          </div>
        </div>
      )}
        {showScaleInfo && (
        <div className="info-popup">
          <div className="info-content">
            <p>{slider_scale_info}</p>
            <button onClick={handleScaleCloseClick}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SliderComponent;
