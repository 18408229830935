// translations.js
const translations = {
    en: {
        subtitle: "Assumptions",
        municipality: "Municipality:",
        areaOfInterest: "Your Area of Interest:",
        bodø2024Effects: "Bodø2024 effects",
        basicForecast: "Basic Forecast",
        largeScaleProjects: "Large-scale Projects",
        publicSectorPlanning: "Public Sector Planning",
        rememberScenario: "Remember Scenario (ME)",
        forgetScenario: "Forget Scenario",
        switchLanguage: "ENG NO",
        aboutMethod: "About Method",
        useExamples: "Use Examples",
        interestedContactUs: "Interested? Contact Us",
        socioEconomicOutcomes: "SOCIO-ECONOMIC OUTCOMES:",
        forecasts: "Forecasts",
        historicalData: "Histoical Data",
        legend: "LEGEND:",
        assumptions: "ASSUMPTIONS:",
        close: "Close",
        methodologyPopup: "In our simulations, we apply the so-called ABM methodology. ABM, or Agent-Based Modeling, is a way to simulate how individuals (called agents) interact in a system, like people in a city or companies in a market. Each agent follows simple rules, and by observing their behavior, we can understand how complex patterns or outcomes emerge in the whole system. The main advantages of using ABM for making socio-economic scenarios for separate municipalities are that it helps capture how individual people or businesses behave and interact, shows how local changes can affect the whole community, and allows for testing different policies or decisions in realistic ways.",
        methodologyLink: "Find more details on methodology, areas of it's application and relevant publications here",
        examplesPopup: "The city of Bodø will be crowned as an European Capital of Culture for 2024. An international research team evaluates cultural life before, during and after 2024. The study focuses on cultural indicators and effects of cultural life on Bodø municipality and surrounding areas. Since the relationships between cultural events and socioeconomic life have appeared too complicated for traditional analysis, LOS Analytics is developing an agent-based model.",
        contactPopup: "Contact us at LOS Analytics AS, Tel. +47 971 56 233, Email. evi@losanalytics.no",
        exampleReportLink: "Download the report here",
        demographics: "Demographics",
        laborMarket: "Labor Market",
        organizationsAndFirms: "Organizations and Firms",
        culture: "Culture",
        education: "Education",
        realEstate: "Real Estate",
        households: "Households",
        hergotBatteryFactory: "Hergot battery factory",
        show: "Show",
        hide: "Hide",
    },
    no: {
        subtitle: "Forutsetninger",
        municipality: "Kommune:",
        areaOfInterest: "Ditt interesseområde:",
        bodø2024Effects: "Bodø2024-effekter",
        basicForecast: "Grunnleggende Prognose",
        largeScaleProjects: "Storskala Prosjekter",
        publicSectorPlanning: "Offentlig Sektor Planlegging",
        rememberScenario: "Husk scenario (ME)",
        forgetScenario: "Glem scenario",
        switchLanguage: "NO ENG",
        aboutMethod: "Om metoden",
        useExamples: "Bruk eksempler",
        interestedContactUs: "Interessert? Kontakt oss",
        socioEconomicOutcomes: "SAMFUNNSØKONOMISKE RESULTATER:",
        historicalData: "Historiske tall",
        forecasts: "Prognoser",
        legend: "TEGNFORKLARING:",
        assumptions: "FORUTSETNINGER:",
        close: "Lukk",
        methodologyPopup: "I våre simuleringer bruker vi den såkalte ABM-metoden. ABM, eller agent-basert modellering, er en måte å simulere hvordan individer (kalt agenter) samhandler i et system, som mennesker i en by eller bedrifter i et marked. Hver agent følger enkle regler, og ved å observere deres oppførsel kan vi forstå hvordan komplekse mønstre eller utfall oppstår i hele systemet. De viktigste fordelene ved å bruke ABM for å lage samfunnsøkonomiske scenarier for separate kommuner er at metoden bidrar til  å fange hvordan individuelle personer eller bedrifter oppfører seg og interagerer, viser hvordan lokale endringer kan påvirke hele samfunnet, og tillater testing av ulike politikker eller beslutninger på realistiske måter.",
        methodologyLink: "Finn mer informasjon om metode, bruksområder og relevante publikasjoner her",
        examplesPopup: "Bodø er en europeisk kulturhovedstad 2024. Et internasjonalt forskerteam evaluerer kulturlivet før, under og etter 2024. Studien fokuserer på kulturindikatorer og effekten av kulturlivet på Bodø kommune og omkringliggende områder. Siden forholdene mellom kulturelle hendelser og samfunnsøkonomisk liv har vist seg for kompliserte for tradisjonell analyse, utvikler LOS Analytics en agentbasert modell.",
        contactPopup: "Kontakt oss hos LOS Analytics AS, Tel. +47 971 56 233, Email. evi@losanalytics.no",
        exampleReportLink: "Last ned rapporten her",
        demographics: "Demografi",
        laborMarket: "Arbeidsmarked",
        organizationsAndFirms: "Organisasjoner og bedrifter",
        culture: "Kultur",
        education: "Utdanning",
        realEstate: "Eiendom",
        households: "Husholdninger",
        hergotBatteryFactory: "Hergot batterifabrikk",
        show: "Vis",
        hide: "Gjem",
    }
  };
  
  export default translations;
  