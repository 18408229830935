import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import logo from './assets/Logo LOS Analytics blue on grey.png';
import monitor2024logo from './assets/Monitor2024logo.jpg'
import {
  FaUsers,
  FaBriefcase,
  FaMusic,
  FaBook,
  FaHome,
  FaHouseUser,
} from 'react-icons/fa';
import { MdFactory } from 'react-icons/md';
import GraphComponent from './GraphComponent';
import SliderComponent from './SliderComponent';
import './App.css';
import translations from './translations';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from 'chart.js';

import { ColorRing } from 'react-loader-spinner'; // Import the spinner component

const API_BASE_URL = process.env.REACT_APP_API_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

// Fix for marker icons not appearing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const App = () => {
  // Declare state variables
  const [accessData, setAccessData] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipality, setMunicipality] = useState('');
  const [areaOfInterestOptions, setAreaOfInterestOptions] = useState([]);
  const [areaOfInterest, setAreaOfInterest] = useState('');
  const [graphData, setGraphData] = useState(null); // State to store graphData
  const [language, setLanguage] = useState('en');
  const [id, setId] = useState(null); // State variable to store the id from URL

  const [showGraphs, setShowGraphs] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [rememberGlobal, setRememberGlobal] = useState(false);
  const [forgetGlobal, setForgetGlobal] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showTitleInfo, setShowTitleInfo] = useState(false);

  // Loading state variables
  const [isOptionsLoading, setIsOptionsLoading] = useState(true);
  const [isGraphDataLoading, setIsGraphDataLoading] = useState(false);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'no' : 'en'));
  };

  // Function to handle clicks outside all pop-ups
  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll('.info-popup');
    let isOutside = true;

    popups.forEach((popup) => {
      if (popup.contains(event.target)) {
        isOutside = false;
      }
    });

    if (isOutside) {
      setShowAbout(false);
      setShowExamples(false);
      setShowContact(false);
      setShowTitleInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch accessData from API
  const fetchAccessData = async (id) => {
    setIsOptionsLoading(true); // Start loading options data
    try {
      const endpoint = id
        ? `${API_BASE_URL}/options/${id}`
        : `${API_BASE_URL}/options/00000000-0000-0000-0000-000000000000`;
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setAccessData(data);

        // Extract municipalities from data.datasets
        const munis = Object.keys(data.datasets);
        setMunicipalities(munis);

        // Set the initial municipality to the first one in the list
        const firstMunicipality = munis[0];
        setMunicipality(firstMunicipality);

        // Get area of interest options for the first municipality
        const firstAreaOptions = data.datasets[firstMunicipality];
        setAreaOfInterestOptions(firstAreaOptions);

        // Set the area of interest to the first available option
        const firstArea = firstAreaOptions[0];
        setAreaOfInterest(firstArea);

        // Set the default language if provided
        if (data.default_language) {
          setLanguage(data.default_language);
        }
      } else {
        console.error('Failed to load access data.');
      }
    } catch (error) {
      console.error('Error fetching access data:', error);
    } finally {
      setIsOptionsLoading(false); // Finish loading options data
    }
  };

  // Fetch accessData when component mounts
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const idFromUrl = searchParams.get('id');
    if (idFromUrl) {
      setId(idFromUrl);
    }
    fetchAccessData(idFromUrl);
  }, []);

  // Map center depends on municipality
  const municipalityCoordinates = {
    Utsira: [59.311, 4.878], // Example coordinates for Utsira
    Bodø: [67.2804, 14.4049], // Example coordinates for Bodø
    Narvik: [68.4385, 17.4279], // Example coordinates for Narvik
    Stavanger: [58.9701, 5.7331], // Example coordinates for Stavanger
    Strand: [59.0259, 6.0402], // Example coordinates for Strand
    Melhus: [63.2511, 10.2889],
  };

  const [mapCenter, setMapCenter] = useState([63.2511, 10.2889]); // Default center

  // Update map center when municipality changes
  useEffect(() => {
    if (municipalityCoordinates[municipality]) {
      setMapCenter(municipalityCoordinates[municipality]);
    }
  }, [municipality]);

  // Fetch graphData dynamically based on municipality and areaOfInterest
  const fetchGraphData = async (municipality, areaOfInterest, language) => {
    setIsGraphDataLoading(true); // Start loading graph data
    try {
      const response = await fetch(
        `${API_BASE_URL}/${municipality}/${areaOfInterest}/${language}`
      );
      if (response.ok) {
        const data = await response.json();
        setGraphData(data);
      } else {
        console.error('Failed to load graph data.');
      }
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setIsGraphDataLoading(false); // Finish loading graph data
    }
  };

  // Fetch graph data whenever municipality, areaOfInterest, or language changes
  useEffect(() => {
    if (municipality && areaOfInterest && language) {
      fetchGraphData(municipality, areaOfInterest, language);
    }
  }, [municipality, areaOfInterest, language]);

  // Initialize sliders and show graphs when graphData is available
  useEffect(() => {
    if (graphData) {
      const initialSliders = graphData.sliders.map((slider) => slider.value);
      setSliders(initialSliders);
      setShowGraphs(
        graphData.graphsSets[0]?.graphs.map((_, index) => index === 0) || []
      );
    }
  }, [graphData]);

  // Update selected graphs based on slider changes
  useEffect(() => {
    if (graphData) {
      const datasetName = graphData.sliders
        .map(
          (slider, index) =>
            `${slider.label.replace(/\s+/g, '')}_${sliders[index]}`
        )
        .join('*');

      const matchingGraphSet = graphData.graphsSets.find(
        (set) => set.name === datasetName
      );

      if (matchingGraphSet) {
        setSelectedGraphs(matchingGraphSet.graphs);
      } else {
        setSelectedGraphs([]);
      }
    }
  }, [sliders, graphData]);

  const handleTitleInfoClick = () => {
    setShowTitleInfo(true);
  };

  const handleCloseClick = () => {
    setShowTitleInfo(false);
  };

  const handleSliderChange = (index, value) => {
    const newSliders = [...sliders];
    newSliders[index] = value;
    setSliders(newSliders);
  };

  const toggleGraph = (index) => {
    const newShowGraphs = [...showGraphs];
    newShowGraphs[index] = !newShowGraphs[index];
    setShowGraphs(newShowGraphs);
  };

  const handleRememberScenario = () => {
    setRememberGlobal(true);
    setTimeout(() => setRememberGlobal(false), 100); // Reset the flag after a brief delay
  };

  const handleForgetScenario = () => {
    setForgetGlobal(true);
    setTimeout(() => setForgetGlobal(false), 100); // Reset the flag after a brief delay
  };

  const handleShowAbout = () => setShowAbout(true);
  const handleCloseAbout = () => setShowAbout(false);
  const handleShowExamples = () => setShowExamples(true);
  const handleCloseExamples = () => setShowExamples(false);
  const handleShowContact = () => setShowContact(true);
  const handleCloseContact = () => setShowContact(false);

  const colorPalette = [
    'rgba(0, 123, 255, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 205, 86, 1)',
    'rgba(54, 162, 235, 1)',
  ];

  const topicIcons = [
    {
      icon: <FaUsers style={{ color: '#FF5733' }} />,
      label: translations[language].demographics,
    },
    {
      icon: <FaBriefcase style={{ color: '#33B5E5' }} />,
      label: translations[language].laborMarket,
    },
    {
      icon: <MdFactory style={{ color: '#8D33FF' }} />,
      label: translations[language].organizationsAndFirms,
    },
    {
      icon: <FaMusic style={{ color: '#FF9800' }} />,
      label: translations[language].culture,
    },
    {
      icon: <FaBook style={{ color: '#4CAF50' }} />,
      label: translations[language].education,
    },
    {
      icon: <FaHome style={{ color: '#FF5722' }} />,
      label: translations[language].realEstate,
    },
    {
      icon: <FaHouseUser style={{ color: '#607D8B' }} />,
      label: translations[language].households,
    },
  ];

  const MapUpdater = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center, 13); // Update map's center and zoom
    }, [center, map]);
    return null;
  };

  const handleMunicipalityChange = (e) => {
    const selectedMunicipality = e.target.value;
    setMunicipality(selectedMunicipality);

    if (municipalityCoordinates[selectedMunicipality]) {
      setMapCenter(municipalityCoordinates[selectedMunicipality]);
    }

    const newAreaOptions = accessData.datasets[selectedMunicipality];
    setAreaOfInterestOptions(newAreaOptions);

    // Set the area of interest to the first option in the new area options
    const firstArea = newAreaOptions[0];
    setAreaOfInterest(firstArea);
  };

  return (
    <div className="app-container">
      <div className="above-card-container">
        <a
          href="https://www.losanalytics.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="LOS Analytics Logo" className="logo" />
        </a>
        <div className="text-and-buttons">
          <div className="title-subtitle" onClick={handleTitleInfoClick}>
            <h2 className="title" >{graphData?.title}
            {areaOfInterest === "bodø2024_effects" && (
                <img src={monitor2024logo} alt="" className="project-logo"/>
              )}</h2>
            <h2 className="subtitle">{graphData?.subtitle}</h2>
          </div>
          {showTitleInfo && (
            <div className="info-popup">
              <div className="info-content">
                <p>{graphData?.project_info}</p>
                <button onClick={handleCloseClick}>Close</button>
              </div>
            </div>
          )}
          <div className="buttons-container">
            <button onClick={handleShowAbout}>
              {translations[language].aboutMethod}
            </button>
            <button onClick={handleShowExamples}>
              {translations[language].useExamples}
            </button>
            <button onClick={handleShowContact}>
              {translations[language].interestedContactUs}
            </button>
          </div>
          <button onClick={toggleLanguage} className="language-button">
            {translations[language].switchLanguage}
          </button>
        </div>
      </div>
      <div className="selection-container">
        {isOptionsLoading ? (
          <div className="loader-container">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
          </div>
        ) : (
          <>
            <div className="municipality-select">
              <label htmlFor="municipality">
                {translations[language].municipality}{' '}
              </label>
              <select
                id="municipality"
                value={municipality}
                onChange={handleMunicipalityChange}
              >
                {municipalities.map((municipalityName, index) => (
                  <option key={index} value={municipalityName}>
                    {municipalityName}
                  </option>
                ))}
              </select>
            </div>
            <div className="area-select">
              <label htmlFor="areaOfInterest">
                {translations[language].areaOfInterest}{' '}
              </label>
              <select
                id="areaOfInterest"
                value={areaOfInterest}
                onChange={(e) => setAreaOfInterest(e.target.value)}
              >
                {areaOfInterestOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>
      <div className="card">
        <div className="charts-container">
          <h2 className="section-name">
            {translations[language].socioEconomicOutcomes}
          </h2>
          <div className="centered-line">
            <span>
              {translations[language].historicalData}{' '}
              <span className="large-arrow">←</span> {graphData?.terminator}{' '}
              <span className="large-arrow">→</span>{' '}
              {translations[language].forecasts}
            </span>
          </div>
          {isGraphDataLoading ? (
            <div className="loader-container">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          ) : (
            selectedGraphs.map((graph, index) => (
              <GraphComponent
                key={index}
                data={{
                  labels: graph.labels,
                  datasets: graph.data.map((serie, serieIndex) => ({
                    label: serie.serieLabel,
                    data: serie.serie,
                    fill: false,
                    borderColor:
                      colorPalette[serieIndex % colorPalette.length],
                    tension: 0.4,
                  })),
                }}
                title={graph.title}
                topic={graph.topic}
                showGraph={showGraphs[index]}
                toggleGraph={() => toggleGraph(index)}
                rememberGlobal={rememberGlobal}
                forgetGlobal={forgetGlobal}
                info={graph.info
                  .split('\n')
                  .map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                language={language}
              />
            ))
          )}
        </div>
        <div className="sliders-container">
          <h2 className="section-name">{translations[language].legend}</h2>
          <div className="legend-container">
            {topicIcons.map((topic, index) => (
              <div key={index} className="legend-item">
                {topic.icon}
                <span className="legend-label">{topic.label}</span>
              </div>
            ))}
          </div>
          <h2 className="section-name">{translations[language].assumptions}</h2>
          {graphData?.sliders.map((slider, index) => (
            <SliderComponent
              key={index}
              label={slider.label_to_show}
              min={slider.min}
              max={slider.max}
              step={slider.step}
              value={sliders[index]}
              onChange={(value) => handleSliderChange(index, value)}
              info={slider.info
                .split('\n')
                .map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))}
              slider_scale_info={slider.slider_scale_info
                .split('\n')
                .map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))}
              scale={slider.scale}
              disabled={isGraphDataLoading} // Disable slider when loading
            />
          ))}
          <button onClick={handleRememberScenario}>
            {translations[language].rememberScenario}
          </button>
          <button onClick={handleForgetScenario}>
            {translations[language].forgetScenario}
          </button>
          <div className="map-container">
            <MapContainer
              center={mapCenter}
              zoom={14}
              scrollWheelZoom={false}
              style={{ height: '400px', width: '100%' }}
            >
              <MapUpdater center={mapCenter} />
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={mapCenter}></Marker>
            </MapContainer>
          </div>
        </div>
        {showAbout && (
          <div className="info-popup">
            <div className="info-content">
              <p>{translations[language].methodologyPopup}</p>
              <p>
                {translations[language].methodologyLink}:{' '}
                <a
                  href="https://www.losanalytics.no/agent-based-modelling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>
                .
              </p>
              <button onClick={handleCloseAbout}>Close</button>
            </div>
          </div>
        )}

        {showExamples && (
          <div className="info-popup">
            <div className="info-content">
              <h2>Bodø2024 </h2>
              <p>{translations[language].examplesPopup}</p>
              <p>
                {translations[language].exampleReportLink}:&nbsp;
                <a
                  href="https://site.nord.no/monitor2024/wp-content/uploads/sites/58/2024/05/FoURapport1062024.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FoURapport1062024
                </a>
              </p>
              <button onClick={handleCloseExamples}>Close</button>
            </div>
          </div>
        )}

        {showContact && (
          <div className="info-popup">
            <div className="info-content">
              <p>{translations[language].contactPopup}</p>
              <button onClick={handleCloseContact}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
