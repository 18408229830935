import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { FaUsers, FaBriefcase, FaMusic, FaBook, FaHome, FaHouseUser } from 'react-icons/fa';
import { MdFactory } from 'react-icons/md';
import translations from './translations';

const GraphComponent = ({ data, title, showGraph, toggleGraph, topic, info, rememberGlobal, forgetGlobal, language }) => {
  const [rememberedLines, setRememberedLines] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  
  const labelPlugin = {
    id: 'lineLabel',
    afterDatasetsDraw: (chart) => {
      const { ctx, chartArea: { right } } = chart;
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (meta.hidden) return;
  
        const lastDataPoint = meta.data[meta.data.length - 1];
        if (lastDataPoint) {
          ctx.save();
          ctx.font = '12px Arial'; // Set font style and size
          ctx.fillStyle = dataset.borderColor || 'black'; // Set color
          ctx.textAlign = 'left';
          ctx.textBaseline = 'middle';
          //ctx.fillText(dataset.label, right + 3, lastDataPoint.y);
                  // Split the label if it's too long
          const label = dataset.label;
          const maxLabelLength = 35; // Adjust this length as needed
          const labelLines = label.length > maxLabelLength
            ? [label.slice(0, maxLabelLength), label.slice(maxLabelLength)]
            : [label];

          // Draw each line with a slight vertical offset
          labelLines.forEach((line, i) => {
            ctx.fillText(line, right + 5, lastDataPoint.y + i * 14); // Adjust spacing as needed
          });
          
          ctx.restore();
        }
      });
    }
  };
  
  const handleClickOutside = (event) => {
    const popups = document.querySelectorAll('.info-popup');
    let isOutside = true;

    popups.forEach((popup) => {
      if (popup.contains(event.target)) {
        isOutside = false;
      }
    });

    if (isOutside) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (forgetGlobal) {
      forgetLines();
    }
  }, [forgetGlobal]);

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleCloseClick = () => {
    setShowInfo(false);
  };
  
  useEffect(() => {
    const rememberLine = () => {
      setRememberedLines((prev) => [
        ...prev,
        ...data.datasets
          .filter((dataset) => dataset.hidden !== true)
          .map((dataset) => ({
            label: dataset.label + ' (ME)',
            data: [...dataset.data],
            fill: false,
            borderColor: dataset.borderColor,
            borderDash: [5, 5],
          })),
      ]);
    };
  
    if (rememberGlobal) {
      rememberLine();
    }
  }, [rememberGlobal, data.datasets]);


  const forgetLines = () => {
    setRememberedLines([]);
  };

  const getIconByTopic = (topic) => {
    switch (topic) {
      case 'DEMOGRAPHICS':
        return <FaUsers style={{ color: '#FF5733' }} />;
      case 'LABOR MARKET':
        return <FaBriefcase style={{ color: '#33B5E5' }} />;
      case 'ORGANIZATION AND FIRMS':
        return <MdFactory style={{ color: '#8D33FF' }} />;
      case 'CULTURE':
          return <FaMusic style={{ color: '#FF9800' }} />;
      case 'EDUCATION':
          return <FaBook style={{ color: '#4CAF50' }} />;
      case 'REAL ESTATE':
          return <FaHome style={{ color: '#FF5722' }} />; // Added real estate icon
      case 'HOUSEHOLDS':
            return <FaHouseUser style={{ color: '#607D8B' }} />; // Added households icon
        default:
        return null;
    }
  };

  return (
    <div className="chart-container">
      <div className="chart-header">
        <div onClick={handleInfoClick}>{getIconByTopic(topic)} {title}</div>
        <div>
{/*           <button onClick={rememberLine}>Remember Line</button>
          <button onClick={forgetLines}>Forget Lines</button> */}
          <button onClick={toggleGraph}>
            {showGraph ? translations[language].hide : translations[language].show}
          </button>
        </div>
      </div>
      {showGraph && (
        <div className="chart-wrapper">
        <Line
        data={{
          ...data,
          datasets: [
            ...rememberedLines,
            ...data.datasets.map((dataset, index) => ({
              ...dataset,
              hidden: index !== 0 ? true : dataset.hidden, // Hide all but the first dataset by default
              clip: false,
            })),
          ],
        }}
        options={{
          plugins: {
            legend: { 
              display: true,
              labels:{
                boxWidth: 40,
                boxHeight: 1,
                //usePointStyle: true,
                //pointStyle: 'line'
                useBorderRadius: true,  // Ensure borders are rounded
                borderRadius: 1.7,
                
              }
            },
          },
          layout: {
            padding: {
              right: 80, // Adjust this value if needed
            },
          },
        }}
        
        plugins={[labelPlugin]} // Add the label plugin here
      />
      </div>
      )}
        {showInfo && (
        <div className="info-popup">
          <div className="info-content">
            <p>{info}</p>
            <button onClick={handleCloseClick}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GraphComponent;
